import { useLocation } from 'react-router-dom'
import { decamelizeKeys } from 'humps'
import Cookies from 'js-cookie'
import queryString from 'query-string'
import { ENDPOINTS, callApi } from './api/callApi'

export const USER_DATA = {
  authToken: 'transfix_auth_token',
  userId: 'transfix_id',
  parentUserId: 'transfix_parent_user_id',
  userFirstName: 'transfix_first_name',
  userLastName: 'transfix_last_name',
  userEmail: 'transfix_email',
  entityId: 'transfix_entity_id',
  userType: 'transfix_user_type',
  userRole: 'transfix_role',
  groups: 'transfix_groups',
  firstName: 'transfix_first_name',
  lastName: 'transfix_last_name',
  email: 'transfix_email',
  applicationType: 'transfix_application_type',
  applicationId: 'transfix_application_id',
  shipperId: 'transfix_shipper_id',
  carrierId: 'transfix_carrier_id',
  needsToFinishInvitation: 'transfix_needs_to_finish_invitation',
  transfixPermissions: 'transfix_permissions',
  shipmentPermissions: 'transfix_shipment_permissions',
  userPermissions: 'transfix_user_permissions',
  displayWelcomeMessage: 'transfix_display_welcome',
  duplicateShipment: 'duplicate_shipment',
  initialLogin: 'transfix_initial_login',
  bcaSigned: 'transfix_bcaSigned',
  showOnboarding: 'transfix_show_onboarding',
  lastLoginDate: 'transfix_last_login',
  lastPageViewed: 'transfix_prev',
  impersonatingUserEmail: 'transfix_impersonating_user_email',
  impersonatingUserToken: 'transfix_impersonating_user_token',
  impersonatingUserId: 'transfix_impersonating_user_id',
  impersonatingCookies: 'transfix_impersonating_cookies',
  children: 'transfix_children',
  redirectToFp: 'transfix_redirect_to_fp',
  redirectToTms: 'transfix_redirect_to_tms',
  app: 'transfix_app',
  cmLeadRegions: 'transfix_cm_lead_regions',
}

const domains = {
  development: 'localhost',
  demo: '.transfix.io',
  staging: '.transfix.io',
  production: '.transfix.io',
}

export const findDomain = (env: keyof typeof domains) => domains[env] || 'localhost'

export const cookieDomain =
  typeof __ENV__ !== 'undefined' ? findDomain(__ENV__ as keyof typeof domains) : 'localhost'

export const getUserCookieValues = () =>
  Object.keys(USER_DATA).reduce(
    (acc, key) => ({ ...acc, [key]: Cookies.get(USER_DATA[key as keyof typeof USER_DATA]) || '' }),
    {} as typeof USER_DATA
  )

export const deleteUserCookies = (deleteLocalStorage: boolean = true) => {
  Object.keys(USER_DATA).forEach(key => {
    Cookies.remove(USER_DATA[key as keyof typeof USER_DATA], { domain: cookieDomain })
  })
  if (deleteLocalStorage) {
    try {
      window.localStorage.clear()
    } catch {
      // if localStorage is not accessible, do nothing
    }
  }
}

export type TLogoutReason = 'accountDeletion' | 'closeSession'

export interface ILogoutProps {
  origin?: string
  /**
   * @default 'closeSession'
   */
  reason?: TLogoutReason
}

export const logout = ({ origin, reason = 'closeSession' }: ILogoutProps = {}) => {
  const targetApp = origin ?? Cookies.get('transfix_app')

  deleteUserCookies()

  if (targetApp === 'fleet-planner' || targetApp === 'fms') {
    if (reason === 'accountDeletion') {
      window.location.href = `${__ROOT__}/fms/new-onboarding/?logoutReason=${reason}`
    } else {
      window.location.href = `${__ROOT__}/fms/login`
    }
  } else if (targetApp === 'tms') {
    // same check we have in src/components/Router.tsx
    const redirect =
      __STAGE__ && !__STAGING_NEW__
        ? window.location.hash.replace('#', '')
        : window.location.pathname

    // /signout page will encodeURIComponent the redirect
    window.location.href = `${__ROOT__}/signout?redirect=${redirect}`
  } else {
    window.location.href = `${__ROOT__}/signout`
  }

  const hashRoutes = __STAGE__ && !__STAGING_NEW__

  if (hashRoutes) {
    // This will trigger a reload of the page that fix logout issues with the Hash router
    // TODO: Remove this when the real signout flow is ready
    window.location.reload()
  }

  return false
}

export const verifyUserCreds = async (userId: string, returnUserDetails = false) => {
  try {
    const userDetails = await callApi({ endpoint: ENDPOINTS.users, id: userId })
    if (returnUserDetails) return userDetails
    return true
  } catch (e) {
    return logout()
  }
}

export const isAuthorizedUser = (allowedUserTypes = ['admin']) => {
  if (!__PROD__) return true
  const { authToken, email, userId, userType } = getUserCookieValues()
  const canViewDashboard = allowedUserTypes.includes(userType)
  const authorizedUser = email && authToken && userId && canViewDashboard
  if (authorizedUser) return verifyUserCreds(userId)
  return logout()
}

export const isTMSAuthorizedUser = async (allowedUserTypes = ['shipper']): Promise<boolean> => {
  const { userId, userType } = getUserCookieValues()

  if (!allowedUserTypes.includes(userType)) return false

  const { user } = await verifyUserCreds(userId, true)
  return user?.redirectToTms
}

export const isFPAuthorizedUser = async (
  allowedUserTypes = ['dispatcher', 'owner_operator']
): Promise<boolean> => {
  const { userId, userType } = getUserCookieValues()

  if (!allowedUserTypes.includes(userType)) return false

  const { user } = await verifyUserCreds(userId, true)
  return user?.redirectToFp
}

export const validKeys = (loginResponse: any) => {
  const responseKeys = Object.keys(loginResponse)
  return responseKeys.filter(key => {
    const responseItem = loginResponse[key]
    return !!responseItem || typeof responseItem === 'boolean'
  })
}

export const storeUserData = (loginResponse: any) =>
  new Promise<void>(resolve => {
    const response: any = decamelizeKeys(loginResponse)
    const validKeyz = validKeys(response)
    validKeyz.forEach((key: string) => {
      const storageKey = `transfix_${key}`
      const permissionsData = key.indexOf('permissions') !== -1
      const groupsData = key.toLowerCase() === 'groups'
      let storageValue = ''
      if (groupsData) {
        const groupNames = Array.isArray(response[key])
          ? response[key].map((group: any) => group.name)
          : response[key]
        storageValue = JSON.stringify(groupNames)
      } else {
        storageValue =
          typeof response[key] === 'string' ? response[key] : JSON.stringify(response[key])
      }
      if (!permissionsData) {
        Cookies.set(storageKey, storageValue, {
          domain: cookieDomain,
          expires: 7,
          secure: cookieDomain !== 'localhost', // only enforce https when not local dev
        })
      }
    })
    resolve()
  })

export const useAccessToken = () => {
  const location = useLocation()
  const { easy_access_token: easyAccessToken } = queryString.parse(location.search)

  return easyAccessToken as string | undefined
}

export const storePermissions = async (userId: string) => {
  const userDetails = await verifyUserCreds(userId, true)

  return storeUserData(userDetails.user).then(() => Promise.resolve(true))
}

export default getUserCookieValues
