import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  PropsWithChildren,
} from 'react'
import { useLocation } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import queryString from 'query-string'
import { callApi, ENDPOINTS } from 'utils/api/callApi'

enum FEATURE_FLAGS {
  carrierAppNfiEmailsTransition = 'carrierAppNfiEmailsTransition',
  disableFmcsaVerification = 'disableFmcsaVerification',
  nfiGetCallList = 'nfiGetCallList',
  nfiComplianceRulesCard = 'nfiComplianceRulesCard',
  nfiOperatingAuthority = 'nfiOperatingAuthority',
  fmsBookingComplianceCheck = 'fmsBookingComplianceCheck',
  fmsNfiBcaForm = 'fmsNfiBcaForm',
  enableCostControlPostMvp = 'enableCostControlPostMvp',
  enableCostControlZipDimension = 'enableCostControlZipDimension',
  carrierAppEldFaqLink = 'carrierAppEldFaqLink',
  carrierMyLoadsSixMonthFilter = 'carrierMyLoadsSixMonthFilter',
  fmsCreateNewVin = 'fmsCreateNewVin',
  onboardingV2DenyAdminRequest = 'onboardingV2DenyAdminRequest',
  /**
   * This flag should not be removed.
   * @see https://transfix.atlassian.net/browse/CE-2496
   */
  carrierFeedbackSurvey = 'carrierFeedbackSurvey',
  activeShipmentsFetchAll = 'activeShipmentsFetchAll',
  userStateSourceUserList = 'userStateSourceUserList',
  userStateSourceUserDetails = 'userStateSourceUserDetails',
  enableIrSourceAttributionReporting = 'enableIrSourceAttributionReporting',
  fmsCarrierOnboardingResubmission = 'fmsCarrierOnboardingResubmission',
  mpQuickSimpathData = 'mpQuickSimpathData',
  fmsUpdatedAmPodOptions = 'fmsUpdatedAmPodOptions',
  fmsUpdatedBrokerPodOptions = 'fmsUpdatedBrokerPodOptions',
  enableCostControlPanel = 'enableCostControlPanel',
  enableTruckerToolsLoadBoardPostStatusInMP = 'enableTruckerToolsLoadBoardPostStatusInMP',
  mpQrConfirmationModal = 'mpQrConfirmationModal',
  fmsPrebookingLoadDetailsMap = 'fmsPrebookingLoadDetailsMap',
  fmsSkipW9 = 'fmsSkipW9',
  fmsSubmitSafetyLetter = 'fmsSubmitSafetyLetter',
  enableEinRule = 'enableEinRule',
  enableFraudValidation = 'enableFraudValidation',
  sqtObjectiveFour = 'sqtObjectiveFour',
  autobiddersConfigurationPage = 'autobiddersConfigurationPage',
  testFlag = 'testFlag',
  loadComments = 'loadComments',
  loadsListFunctionalStatusTag = 'loadsListFunctionalStatusTag',
  stagingRulesDebugger = 'stagingRulesDebugger',
  confettis = 'confettis',
  fmsOffersPage = 'fmsOffersPage',
  fmsAutomatedStatusUpdates = 'fmsAutomatedStatusUpdates',
  refreshNormFile = 'refreshNormFile',
  mpRmsRepricedRates = 'mpRmsRepricedRates',
  redirectToNewShipmentDetails = 'redirectToNewShipmentDetails',
  uncancelShipment = 'uncancelShipment',
  bumbleBeeDDigest = 'bumbleBeeDDigest',
  fmsEquipmentListingPage = 'fmsEquipmentListingPage',
  costInStarkDashboardStrategyInformation = 'costInStarkDashboardStrategyInformation',
  stormStrategyManagement = 'stormStrategyManagement',
  mpStormErrorHandling = 'mpStormErrorHandling',
  stormAwardManagement = 'stormAwardManagement',
  rfpLanesImportExportEnabled = 'rfpLanesImportExportEnabled',
  mpStormLaneRefresh = 'mpStormLaneRefresh',
  mpSQMPhase1 = 'mpSQMPhase1',
  movePodWarningToTopInCarrierApp = 'movePodWarningToTopInCarrierApp',
  enableSavedLanesMP = 'enableSavedLanesMP',
  sqtAppliedRulesData = 'sqtAppliedRulesData',
  disableVerifiedAdminOverRide = 'disableVerifiedAdminOverRide',
  fmsDriverTwoLoadShare = 'fmsDriverTwoLoadShare',
  displayFacilityName = 'displayFacilityName',
  stormUrlAwardedLanes = 'stormUrlAwardedLanes',
  oopAutoIssueCreate = 'oopAutoIssueCreate',
  mpMergeBulkFacilities = 'mpMergeBulkFacilities',
  suggested_appointments_persist_to_appointment = 'suggested_appointments_persist_to_appointment',
  mpStepsToResolve = 'mpStepsToResolve',
  allowNewMyLoadsTable = 'allowNewMyLoadsTable',
  mpArchiveVendors = 'mpArchiveVendors',
  carrierPortalMaintenance = 'carrierPortalMaintenance',
  shipperPortalMaintenance = 'shipperPortalMaintenance',
  marketplaceMaintenance = 'marketplaceMaintenance',
  fpQrConfirmationModal = 'fpQrConfirmationModal',
  shipper_mileage_program = 'shipper_mileage_program',
  mpRolledShipmentStatus = 'mpRolledShipmentStatus',
  shipperAppManualCloseBid = 'shipperAppManualCloseBid',
  mpShipperPreferencesInFacilityPage = 'mpShipperPreferencesInFacilityPage',
  mpAppointmentAutomations = 'mpAppointmentAutomations',
  mpNewPodNames = 'mpNewPodNames',
  mpGroupDeletion = 'mpGroupDeletion',
  hideAccountUndeleteButtonForDeletedParent = 'hideAccountUndeleteButtonForDeletedParent',
  tcmPricingStrategy = 'tcmPricingStrategy',
  fleetPlannerOnboardingV2 = 'fleetPlannerOnboardingV2',
  mpRmsGqlBulkActions = 'mpRmsGqlBulkActions',
  mpStormAggOverviewEnabled = 'mpStormAggOverviewEnabled',
  mpIssueNavigationOverview = 'mpIssueNavigationOverview',
  mpSurfaceIssueMetadata = 'mpSurfaceIssueMetadata',
  mpShowLocalTimeAtStop = 'mpShowLocalTimeAtStop',
  mpWorkflowManagerTAMUpdates = 'mpWorkflowManagerTAMUpdates',
  mpWorkflowManagerUsabilityImprovements = 'mpWorkflowManagerUsabilityImprovements',
  setupGuideHasInsuranceCheck = 'setupGuideHasInsuranceCheck',
  mpTaskSnoozingV2 = 'mpTaskSnoozingV2',
  mpTaskSnoozingAssignToMe = 'mpTaskSnoozingAssignToMe',
  shipperAppRfpLaneNotes = 'shipperAppRfpLaneNotes',
  blockAppAccessForUnverifiedUsers = 'blockAppAccessForUnverifiedUsers',
  mpTaskOnShipmentDetailPage = 'mpTaskOnShipmentDetailPage',
  loadIfInViewportEnabled = 'loadIfInViewportEnabled',
  displayPriceRequestCount = 'displayPriceRequestCount',
  shipperAppActivityTimestamps = 'shipperAppActivityTimestamps',
  shipperAppAllowRfpManagerRebid = 'shipperAppAllowRfpManagerRebid',
  rfpAnalyticDataRequests = 'rfpAnalyticDataRequests',
  mpCarrierScorecardInTAM = 'mpCarrierScorecardInTAM',
  shipperAppGraphqlEditLaneContracts = 'shipperAppGraphqlEditLaneContracts',
  single_invoice_required_enabled = 'single_invoice_required_enabled',
  single_invoice_required_enabled_m2 = 'single_invoice_required_enabled_m2',
  shipperAppEquipmentAttributes = 'shipperAppEquipmentAttributes',
  enableStagingAnalytics = 'enableStagingAnalytics',
  mpWMTrackingTriggers = 'mpWMTrackingTriggers',
  mpWMFCFSDueTimeTriggers = 'mpWMFCFSDueTimeTriggers',
  fmsSavedLanesCountrySelector = 'fmsSavedLanesCountrySelector',
  optimized_shipment_query = 'optimized_shipment_query',
}

export type featureFlagKeys = keyof typeof FEATURE_FLAGS

export interface IApiFeatureFlag {
  key: featureFlagKeys
  enabled: boolean
}

/**
 * Outline which layer set the flag to the given value
 * 'default' < 'api' < 'qs' < 'setting'
 */
export type sourceType = 'default' | 'api' | 'qs' | 'setting'

export interface IFeatureFlagValue {
  enabled: boolean
  source: sourceType
}

type FeatureFlags = {
  [key in featureFlagKeys]: IFeatureFlagValue
}

interface IUseFeatureFlags {
  readonly flags: FeatureFlags
  getFlag: (key: featureFlagKeys) => IFeatureFlagValue
  setFlag: (key: featureFlagKeys, value: boolean, source: sourceType) => void
  error?: string
  hasLoaded: boolean
}

export const FEATURES_DEFAULTS: FeatureFlags = {
  carrierAppNfiEmailsTransition: {
    enabled: false,
    source: 'default',
  },
  disableFmcsaVerification: {
    enabled: false,
    source: 'default',
  },
  nfiGetCallList: {
    enabled: false,
    source: 'default',
  },
  nfiComplianceRulesCard: {
    enabled: false,
    source: 'default',
  },
  nfiOperatingAuthority: {
    enabled: false,
    source: 'default',
  },
  fmsBookingComplianceCheck: {
    enabled: false,
    source: 'default',
  },
  fmsNfiBcaForm: {
    enabled: false,
    source: 'default',
  },
  enableCostControlPostMvp: {
    enabled: false,
    source: 'default',
  },
  enableCostControlZipDimension: {
    enabled: false,
    source: 'default',
  },
  carrierAppEldFaqLink: {
    enabled: false,
    source: 'default',
  },
  single_invoice_required_enabled: {
    enabled: false,
    source: 'default',
  },
  single_invoice_required_enabled_m2: {
    enabled: false,
    source: 'default',
  },
  carrierMyLoadsSixMonthFilter: {
    enabled: false,
    source: 'default',
  },
  fmsCreateNewVin: {
    enabled: false,
    source: 'default',
  },
  onboardingV2DenyAdminRequest: {
    enabled: false,
    source: 'default',
  },
  carrierFeedbackSurvey: {
    enabled: false,
    source: 'default',
  },
  activeShipmentsFetchAll: {
    enabled: false,
    source: 'default',
  },
  userStateSourceUserList: {
    enabled: false,
    source: 'default',
  },
  userStateSourceUserDetails: {
    enabled: false,
    source: 'default',
  },
  enableIrSourceAttributionReporting: {
    enabled: false,
    source: 'default',
  },
  fmsCarrierOnboardingResubmission: {
    enabled: false,
    source: 'default',
  },
  mpQuickSimpathData: {
    enabled: false,
    source: 'default',
  },
  fmsUpdatedAmPodOptions: {
    enabled: false,
    source: 'default',
  },
  fmsUpdatedBrokerPodOptions: {
    enabled: false,
    source: 'default',
  },
  enableCostControlPanel: {
    enabled: false,
    source: 'default',
  },
  enableTruckerToolsLoadBoardPostStatusInMP: {
    enabled: false,
    source: 'default',
  },
  mpQrConfirmationModal: {
    enabled: false,
    source: 'default',
  },
  fmsPrebookingLoadDetailsMap: {
    enabled: false,
    source: 'default',
  },
  fmsSkipW9: {
    enabled: false,
    source: 'default',
  },
  fmsSubmitSafetyLetter: {
    enabled: false,
    source: 'default',
  },
  enableEinRule: {
    enabled: false,
    source: 'default',
  },
  enableFraudValidation: {
    enabled: false,
    source: 'default',
  },
  autobiddersConfigurationPage: {
    enabled: false,
    source: 'default',
  },
  movePodWarningToTopInCarrierApp: {
    enabled: false,
    source: 'default',
  },
  testFlag: {
    enabled: false,
    source: 'default',
  },
  loadComments: {
    enabled: false,
    source: 'default',
  },
  loadsListFunctionalStatusTag: {
    enabled: false,
    source: 'default',
  },
  stagingRulesDebugger: {
    enabled: false,
    source: 'default',
  },
  enableStagingAnalytics: {
    enabled: false,
    source: 'default',
  },
  /**
   * Enables Confettis
   */
  confettis: {
    enabled: false,
    source: 'default',
  },
  /**
   * Enables new FMS offers dashboard and page
   */
  fmsOffersPage: {
    enabled: false,
    source: 'default',
  },
  /**
   * Enables new FMS automated status updates features
   */
  fmsAutomatedStatusUpdates: {
    enabled: false,
    source: 'default',
  },
  /**
   * Enable driver two flow in load share on FMS
   * https://transfix.atlassian.net/browse/CE-542
   */
  fmsDriverTwoLoadShare: {
    enabled: false,
    source: 'default',
  },
  /**
   * When enabled, users can open a modal to submit a norm file for refresh (instant rfp)
   */
  refreshNormFile: {
    enabled: false,
    source: 'qs',
  },
  /**
   * Feature flag for repricing changes to the shipper rates page.
   */
  mpRmsRepricedRates: {
    enabled: true,
    source: 'default',
  },
  /**
   * Feature flag for showing the Price Agreement Record card on shipment details.
   */
  mpSQMPhase1: {
    enabled: true,
    source: 'default',
  },
  /**
   * Used to redirect to new shipment details page after issue creation for testing
   */
  redirectToNewShipmentDetails: {
    enabled: false,
    source: 'default',
  },
  /** Enables uncancel button in shipment details header
   * @see https://transfix.atlassian.net/browse/SDR-447
   */
  uncancelShipment: {
    enabled: true,
    source: 'default',
  },
  /**
   * BumbleBee Daily Digest
   */
  bumbleBeeDDigest: {
    enabled: false,
    source: 'default',
  },
  /** Enables Equipment Listing Page */
  fmsEquipmentListingPage: {
    enabled: false,
    source: 'default',
  },
  /** Displays strategy information within the cost in stark dashboard * */
  costInStarkDashboardStrategyInformation: {
    enabled: false,
    source: 'default',
  },
  /** Enables RFP lanes data import/export */
  rfpLanesImportExportEnabled: {
    enabled: false,
    source: 'default',
  },
  /** Enables pricing strategy management in Stark * */
  stormStrategyManagement: {
    enabled: false,
    source: 'default',
  },
  /** Enables error handling features in the Storm RFP dashboard. */
  mpStormErrorHandling: {
    enabled: false,
    source: 'default',
  },
  /** Enables award management in Stark * */
  stormAwardManagement: {
    enabled: false,
    source: 'default',
  },
  /** Enables refreshing individual lanes in Storm. */
  mpStormLaneRefresh: {
    enabled: false,
    source: 'default',
  },
  /** Enables the saved lanes general information card on marketplace's carrier page */
  enableSavedLanesMP: {
    enabled: false,
    source: 'default',
  },
  /** Displays SQT applied rules data */
  sqtAppliedRulesData: {
    enabled: false,
    source: 'default',
  },
  displayFacilityName: {
    enabled: false,
    source: 'default',
  },
  mpShowLocalTimeAtStop: {
    enabled: false,
    source: 'default',
  },
  /** Turn on Workflow Manager TAM Updates */
  mpWorkflowManagerTAMUpdates: {
    enabled: false,
    source: 'default',
  },
  /** Turn on Usability Improvements to WFM & TAM */
  mpWorkflowManagerUsabilityImprovements: {
    enabled: false,
    source: 'default',
  },
  /** Display storm dashboard url instead of google sheets if pricing workspace exists */
  stormUrlAwardedLanes: {
    enabled: false,
    source: 'default',
  },
  /** Auto-Create Issue When Shipment Accessorial is OOP */
  oopAutoIssueCreate: {
    enabled: true,
    source: 'default',
  },
  /** Will show the controls to merge multiple facilities at the same time */
  mpMergeBulkFacilities: {
    enabled: false,
    source: 'default',
  },
  sqtObjectiveFour: {
    enabled: false,
    source: 'default',
  },
  mpStepsToResolve: {
    enabled: false,
    source: 'default',
  },
  /** Show suggested times feature in rogers */
  suggested_appointments_persist_to_appointment: {
    enabled: false,
    source: 'default',
  },
  allowNewMyLoadsTable: {
    enabled: false,
    source: 'default',
  },
  mpArchiveVendors: {
    enabled: false,
    source: 'default',
  },
  carrierPortalMaintenance: {
    enabled: false,
    source: 'default',
  },
  shipperPortalMaintenance: {
    enabled: false,
    source: 'default',
  },
  marketplaceMaintenance: {
    enabled: false,
    source: 'default',
  },
  /** Display the QR code confirmation modal when changing drivers */
  fpQrConfirmationModal: {
    enabled: false,
    source: 'default',
  },
  /** Enables new shipper mileage program section under the shipper profile page */
  shipper_mileage_program: {
    enabled: false,
    source: 'default',
  },
  /** Give the users the ability to change the rolled status of a shipment */
  mpRolledShipmentStatus: {
    enabled: false,
    source: 'default',
  },
  /** Enables ability to manually close RFP Bidding Window */
  shipperAppManualCloseBid: {
    enabled: false,
    source: 'default',
  },
  /** Show shipper preferences in facility page */
  mpShipperPreferencesInFacilityPage: {
    enabled: false,
    source: 'default',
  },
  /** Show appointment automations in shipper page > operations tab */
  mpAppointmentAutomations: {
    enabled: false,
    source: 'default',
  },
  /** Show pods name by color instead of numbers in booking and TAM page */
  mpNewPodNames: {
    enabled: false,
    source: 'default',
  },
  /** Allow groups to be deleted */
  mpGroupDeletion: {
    enabled: false,
    source: 'default',
  },
  /** Hide button to undelete user account in user details page */
  hideAccountUndeleteButtonForDeletedParent: {
    enabled: false,
    source: 'default',
  },
  /** Show tcm pricing strategy in cost audit */
  tcmPricingStrategy: {
    enabled: false,
    source: 'default',
  },
  /** Uses the new design and features for fleet planner user sign up */
  fleetPlannerOnboardingV2: {
    enabled: false,
    source: 'default',
  },
  /** Enables submitting RMS bulk actions via GraphQL */
  mpRmsGqlBulkActions: {
    enabled: false,
    source: 'default',
  },
  mpStormAggOverviewEnabled: {
    enabled: false,
    source: 'default',
  },
  /** Enables showing issue metadata on Marketplace */
  mpSurfaceIssueMetadata: {
    enabled: false,
    source: 'default',
  },
  /** Enables issue overview nav dropdown in marketplace */
  mpIssueNavigationOverview: {
    enabled: false,
    source: 'default',
  },
  /** In the Carrier App Setup Guide, use hasInsurance field to determine Insurance Display */
  setupGuideHasInsuranceCheck: {
    enabled: false,
    source: 'default',
  },
  /** Shows selector instead of a switch */
  mpTaskSnoozingAssignToMe: {
    enabled: false,
    source: 'default',
  },
  /** Users being able to select a date instead of a fixed time */
  mpTaskSnoozingV2: {
    enabled: false,
    source: 'default',
  },
  /** Allow users to provide notes per lane in RFP Wizard */
  shipperAppRfpLaneNotes: {
    enabled: false,
    source: 'default',
  },
  /** Block users from signing in if they are not verified */
  blockAppAccessForUnverifiedUsers: {
    enabled: false,
    source: 'default',
  },
  /** Shows tasks in shipment detail page */
  mpTaskOnShipmentDetailPage: {
    enabled: false,
    source: 'default',
  },
  /** Enable LoadIfInViewport component to load components if they are in the viewport */
  loadIfInViewportEnabled: {
    enabled: false,
    source: 'default',
  },
  /** Display price request count in SQT */
  displayPriceRequestCount: {
    enabled: false,
    source: 'default',
  },
  /** Display timestamp in activity log */
  shipperAppActivityTimestamps: {
    enabled: false,
    source: 'default',
  },
  shipperAppAllowRfpManagerRebid: {
    enabled: false,
    source: 'default',
  },
  /** Enables analytic data requests for RFP */
  rfpAnalyticDataRequests: {
    enabled: false,
    source: 'default',
  },
  mpCarrierScorecardInTAM: {
    enabled: false,
    source: 'default',
  },
  disableVerifiedAdminOverRide: {
    enabled: false,
    source: 'default',
  },
  /** Enables shipper app edit lane contracts page powered by graphql */
  shipperAppGraphqlEditLaneContracts: {
    enabled: false,
    source: 'default',
  },
  /** Enables shipment equipment attributes in shipper app */
  shipperAppEquipmentAttributes: {
    enabled: false,
    source: 'default',
  },
  /** Show tracking triggers for Workflow Manager */
  mpWMTrackingTriggers: {
    enabled: false,
    source: 'default',
  },
  /** Show FCFS Due Time triggers for Workflow Manager */
  mpWMFCFSDueTimeTriggers: {
    enabled: false,
    source: 'default',
  },
  /** Enables country selector on Saved Lanes screen */
  fmsSavedLanesCountrySelector: {
    enabled: false,
    source: 'default',
  },
  /** Enable Optimize shipment query */
  optimized_shipment_query: {
    enabled: false,
    source: 'default',
  },
}

const useFeatureFlags = (): IUseFeatureFlags => {
  const [flags, _setFlags] = useState<FeatureFlags>(FEATURES_DEFAULTS)
  const [error, setError] = useState<string>()
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)
  const { search } = useLocation()

  // Manually set a given flag to a given value, locally
  const setFlag = useCallback((key: featureFlagKeys, value: boolean) => {
    _setFlags(currentFlags => ({
      ...currentFlags,
      [key]: { enabled: value, source: 'setting' },
    }))
  }, [])

  const getFlag = useCallback((key: featureFlagKeys): IFeatureFlagValue => flags[key], [flags])

  /**
   * useEffectOnce to fetch feature flag values from the API,
   * then reconcile all values with the following precedence:
   *    Query String Value > Feature Flag API Value > Default Rogers Value
   *
   * @note any manual update using setFlag after page load will override above calculation locally.
   */
  useEffectOnce(() => {
    const fetchFlags = async () => {
      setError(undefined)

      try {
        // API Flags
        const { flags: apiFlagsArray }: { flags: IApiFeatureFlag[] } = await callApi({
          endpoint: ENDPOINTS.featureFlags,
        })
        const apiFlags = {} as FeatureFlags

        apiFlagsArray.forEach(apiFlag => {
          apiFlags[apiFlag.key] = { enabled: apiFlag.enabled, source: 'api' }
        })

        // Query String Flags
        const parsedQueryString = queryString.parse(search)
        const queryStringFlags = {} as FeatureFlags

        Object.keys(parsedQueryString).forEach(queryStringKey => {
          queryStringFlags[queryStringKey as featureFlagKeys] = {
            enabled: parsedQueryString[queryStringKey] !== 'false',
            source: 'qs',
          }
        })

        _setFlags(defaultFlags => ({
          ...defaultFlags,
          ...apiFlags,
          ...queryStringFlags,
        }))
      } catch (e) {
        setError(e)
      } finally {
        // unblock view and handle error, if there's any, in it
        setHasLoaded(true)
      }
    }

    fetchFlags()
  })

  return { flags, hasLoaded, error, setFlag, getFlag }
}

export interface IFeatureFlagsState extends IUseFeatureFlags {
  errors?: string
}

/**
 * Init the context
 */
const featureFlagsDefaultState: IFeatureFlagsState = {
  flags: FEATURES_DEFAULTS,
  hasLoaded: false,
  error: undefined,
  getFlag: () => FEATURES_DEFAULTS.testFlag,
  setFlag: () => undefined,
}

export const FeatureFlagsContext = createContext<IFeatureFlagsState>(featureFlagsDefaultState)

/**
 * Provide default Provider state component to wrap around app
 */
export function FeatureFlagsProvider({ children }: PropsWithChildren<{}>) {
  const { error, flags, getFlag, hasLoaded, setFlag } = useFeatureFlags()
  const featureFlagsState = useMemo(() => {
    return {
      flags,
      hasLoaded,
      error,
      setFlag,
      getFlag,
    }
  }, [error, flags, getFlag, hasLoaded, setFlag])

  return (
    <FeatureFlagsContext.Provider value={featureFlagsState}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}

export const useFeatureFlagContext = () => useContext(FeatureFlagsContext)

/**
 * Easy way to access a feature flag value
 * @example const enabled = useFeatureFlag('carrierFeedbackSurvey')
 */
export const useFeatureFlag = (keyName: featureFlagKeys): boolean => {
  const { getFlag } = useFeatureFlagContext()
  const { enabled } = getFlag(keyName)
  return enabled
}
