import React, {
  useCallback,
  useMemo,
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react'
import { useEffectOnce } from 'react-use'
import cloneDeep from 'lodash/cloneDeep'
import { IUserDetails, IUserPermissions, IShipmentPermission } from 'types'
import { getUserDetails as getApiUserDetails } from 'utils/api/getUserDetails'
import { ILogoutProps, getUserCookieValues, logout, storeUserData } from 'utils/userAuth'

export interface IApiUserDetailsState {
  loading: boolean
  userDetails: IUserDetails | null
  userPermissions: IUserPermissions | null
  shipmentPermissions: IShipmentPermission[]
  authToken: string | null
  error: string | null
}

export interface IUserDetailsState extends IApiUserDetailsState {
  updateUserDetails?: () => Promise<void>
  clearUserDetails?: () => void
  logout: (props?: ILogoutProps) => void
  userIsAuthenticated?: boolean
  isFPUser?: boolean
  isMarketplaceAdmin?: boolean
  isTMSUser?: boolean
}

const userDetailsInitialState: IApiUserDetailsState = {
  loading: true,
  userDetails: null,
  userPermissions: null,
  shipmentPermissions: [],
  error: null,
  authToken: null,
}

const UserDetailsContext = createContext<IUserDetailsState>({
  ...userDetailsInitialState,
  logout,
})

// Retrieves the cookies values from staging and stores them as own cookies
const attemptLocalLogin = async () => {
  const { localLogin } = Object.fromEntries(new URLSearchParams(window.location.search))

  if (!localLogin) {
    return
  }

  try {
    const localLoginObj = JSON.parse(localLogin)

    await storeUserData({
      ...localLoginObj,
      id: localLoginObj.userId,
    })

    // force a browser reload outside of react router.
    window.location.href = __ROOT__
  } catch (error) {
    window.console.warn('login info is malformed')
  }
}

/**
 * Static state updated via the useEffect below to expose userDetailsState to parts of
 * our app outside of the react lifecycle. This enables a smoother transition.
 */
let staticUserState = cloneDeep(userDetailsInitialState)

export function UserDetailsProvider({ children }: PropsWithChildren<{}>) {
  const [userDetailsState, setUserDetailsState] = useState(userDetailsInitialState)

  const clearUserDetails = useCallback(() => {
    setUserDetailsState(userDetailsInitialState)
  }, [])

  const callGetUserDetails = useCallback(async () => {
    if (__ENV__ === 'development') {
      await attemptLocalLogin()
    }

    const { authToken, userId } = getUserCookieValues()

    if (!userId) {
      setUserDetailsState(currentState => ({
        ...currentState,
        loading: false,
      }))

      return
    }

    try {
      const { shipmentPermissions, user, userPermissions } = await getApiUserDetails(userId)

      setUserDetailsState({
        userDetails: user,
        userPermissions,
        shipmentPermissions,
        authToken,
        loading: false,
        error: null,
      })
    } catch (error) {
      setUserDetailsState(currentState => ({
        ...currentState,
        loading: false,
        error: 'There was an error getting User Details',
      }))
    }
  }, [])

  useEffectOnce(() => {
    callGetUserDetails()
  })

  /**
   * useEffect to update static state for getters used outside of react lifecycle.
   */
  useEffect(() => {
    staticUserState = cloneDeep(userDetailsState)
  }, [userDetailsState])

  const userDetailsStateMemo = useMemo(() => {
    const userIsAuthenticated = !userDetailsState.loading && Boolean(userDetailsState.authToken)
    const { redirectToFp, redirectToTms, userType } = userDetailsState.userDetails || {}
    const isFPUser = userIsAuthenticated && redirectToFp
    const isTMSUser = userIsAuthenticated && redirectToTms
    const isMarketplaceAdmin = !isTMSUser && !isFPUser && userType === 'admin'

    return {
      ...userDetailsState,
      updateUserDetails: callGetUserDetails,
      clearUserDetails,
      logout,
      userIsAuthenticated,
      isFPUser,
      isTMSUser,
      isMarketplaceAdmin,
    }
  }, [callGetUserDetails, clearUserDetails, userDetailsState])

  return (
    <UserDetailsContext.Provider value={userDetailsStateMemo}>
      {children}
    </UserDetailsContext.Provider>
  )
}

export const useUserDetails = () => useContext(UserDetailsContext)

/**
 * Getters used outside of react lifecycle 👀
 */
export const getUserDetails = () => Object.freeze(staticUserState.userDetails)
export const getUserPermissions = () => Object.freeze(staticUserState.userPermissions)
export const getUserShipmentPermissions = () => Object.freeze(staticUserState.shipmentPermissions)
export const getUserAuthToken = () => Object.freeze(staticUserState.authToken)
